// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppContent_container__qEXmD {
  margin-top: 47px;
  height: inherit;
  gap: 2rem;
  max-height: calc(100vh - 30px);
}

.AppContent_leftChild__G2cbC {
  margin-top: 1rem;
}

.AppContent_rightChild__XrdGW {
  max-height: inherit;
  overflow-y: auto;
  padding-left: 0.5rem;
}

@media only screen and (max-width: 400px) {
  .AppContent_container__qEXmD {
    margin-bottom: 160px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AppContent/AppContent.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,SAAA;EACA,8BAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACE,mBAAA;EACA,gBAAA;EACA,oBAAA;AACF;;AAEA;EACI;IACE,oBAAA;EACJ;AACF","sourcesContent":[".container{\n    margin-top: 47px;\n    height: inherit;\n    gap: 2rem;\n    max-height: calc(100vh - 30px);\n}\n\n.leftChild{\n    margin-top: 1rem;\n}\n\n.rightChild{\n  max-height: inherit;\n  overflow-y: auto;\n  padding-left: 0.5rem;\n}\n\n@media only screen and (max-width: 400px) {\n    .container {\n      margin-bottom: 160px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AppContent_container__qEXmD`,
	"leftChild": `AppContent_leftChild__G2cbC`,
	"rightChild": `AppContent_rightChild__XrdGW`
};
export default ___CSS_LOADER_EXPORT___;
