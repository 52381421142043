// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Selector_linkText__FwgjA {
  font-size: small;
  cursor: pointer;
}

.Selector_container__TMdXV {
  max-height: calc(100vh - 47px - 90px);
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Selector/Selector.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,qCAAA;EACA,gBAAA;AACJ","sourcesContent":[".linkText{\n    font-size: small;\n    cursor: pointer;\n}\n\n.container{\n    max-height: calc(100vh - 47px - 90px);\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkText": `Selector_linkText__FwgjA`,
	"container": `Selector_container__TMdXV`
};
export default ___CSS_LOADER_EXPORT___;
