// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_container__iICw7 {
  position: fixed;
  bottom: 0;
  display: block !important;
  height: fit-content;
  background-color: #262626;
  padding: 1rem;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,yBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,WAAA;AACJ","sourcesContent":[".container{\n    position: fixed;\n    bottom: 0;\n    display: block !important;\n    height: fit-content;\n    background-color: #262626;\n    padding: 1rem;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Footer_container__iICw7`
};
export default ___CSS_LOADER_EXPORT___;
